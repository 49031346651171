/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import LoginPage from '../LoginPage/LoginPage';
import HomePage from '../HomePage/HomePage';
import NotFound from '../NotFound/NotFound';
import Header from '../../components/Header/Header';
import { Context as UserContext } from '../../context/UserContext';
import OfferPage from '../OfferPage/OfferPage';
import OffersPage from '../OffersPage/OffersPage';
import SpecialOffersPage from '../SpecialOffersPage/SpecialOffersPage';
import SpecialOfferPage from '../SpecialOfferPage/SpecialOfferPage';
import ReviewsPage from '../ReviewsPage/ReviewsPage';
import ReviewPage from '../ReviewPage/ReviewPage';
import FreeProductsPage from '../FreeProductsPage/FreeProductsPage';
import FreeProductPage from '../FreeProductPage/FreeProductPage';
import PromosPage from '../PromosPage/PromosPage';
import PromoPage from '../PromoPage/PromoPage';
import CrossSellsPage from '../CrossSellsPage/CrossSellsPage';
import CrossSellPage from '../CrossSellPage/CrossSellPage';
import WebsitesPage from '../WebsitesPage/WebsitesPage';
import BannersPage from '../BannersPage/BannersPage';
import ProofPopupsPage from '../ProofPopups/ProofPopups';
import UrlParamsPage from '../UrlParamsPage/UrlParamsPage';
import UsersPage from '../UsersPage/UsersPage';
import PaymentRoutersPage from '../PaymentRoutersPage/PaymentRoutersPage';
import VerifiHistoryPage from '../VerifiHistoryPage/VerifiHistoryPage';

export default function App() {
	const history = useHistory();
	const [pageName, setPageName] = useState('');

	const {
		state: { user, isTokenValid },
		validate,
		logout,
	} = useContext(UserContext);

	useEffect(() => {
		if (!localStorage.getItem('token')) {
			return history.push('/login');
		}

		if (localStorage.getItem('token')) {
			validate({ token: localStorage.getItem('token') });
		}
	}, []);

	useEffect(() => {
		if (!isTokenValid) {
			history.push('/login');
		}
	}, [isTokenValid]);

	async function handleLogout(id) {
		await logout(id);
		localStorage.removeItem('token');
		history.push('/login');
	}

	return (
		<>
			<Header pageName={pageName} user={user} onLogout={handleLogout} />
			<Switch>
				<Route path='/login'>
					<LoginPage />
				</Route>
				<Route path='/offers/:id'>
					<OfferPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route exact path='/offers'>
					<OffersPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route path='/specialOffers/:id'>
					<SpecialOfferPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route exact path='/specialOffers'>
					<SpecialOffersPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route exact path='/paymentRouters'>
					<PaymentRoutersPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route exact path='/promos'>
					<PromosPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route path='/promos/:id'>
					<PromoPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route exact path='/reviews'>
					<ReviewsPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route path='/reviews/:id'>
					<ReviewPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route exact path='/freeProducts'>
					<FreeProductsPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route path='/freeProducts/:id'>
					<FreeProductPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route exact path='/crossSells'>
					<CrossSellsPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route path='/crossSells/:id'>
					<CrossSellPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route exact path='/websites'>
					<WebsitesPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route exact path='/banners'>
					<BannersPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route exact path='/proofPopups'>
					<ProofPopupsPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route exact path='/urlParams'>
					<UrlParamsPage onRender={(name) => setPageName(name)} />
				</Route>
				<Route exact path='/verifiHistory'>
					<VerifiHistoryPage onRender={(name) => setPageName(name)} />
				</Route>

				<Route exact path='/users'>
					<UsersPage onRender={(name) => setPageName(name)} />
				</Route>

				<Route exact path='/'>
					<HomePage onRender={(name) => setPageName(name)} />
				</Route>
				<Route component={NotFound} />
			</Switch>
		</>
	);
}
