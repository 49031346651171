import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Error from '../../components/Error/Error';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import { Context as FreeProductsContext } from '../../context/FreeProductsContext';
import { Context as FreeProductContext } from '../../context/FreeProductContext';
import { Context as UserContext } from '../../context/UserContext';

const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 750,
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
	},
	title: {
		marginTop: theme.spacing(4),
	},
	topButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(4),
	},
	modalButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: theme.spacing(3),
	},
}));

export default function FreeProductsPage({ onRender }) {
	const classes = useStyles();
	const history = useHistory();

	// STATE
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const [freeProductToDelete, setFreeProductToDelete] = useState(null);

	// CONTEXTS
	const {
		state: { user },
	} = useContext(UserContext);

	const {
		state: { freeProducts, loading: freeProductsLoading, error: freeProductsError },
		loadFreeProducts,
	} = useContext(FreeProductsContext);

	const {
		state: { freeProduct, error: freeProductError },
		createFreeProduct,
		deleteFreeProduct,
		updateFreeProduct,
	} = useContext(FreeProductContext);

	// EFFECTS
	useEffect(() => {
		if (user || freeProduct) {
			loadFreeProducts();
		}

		onRender('Free products');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, freeProduct]);

	function handleDeleteFreeProduct(id) {
		setDeleteModalOpen(true);
		setFreeProductToDelete(id);
	}

	function handleActivateFreeProduct(id, currentActive) {
		updateFreeProduct(id, {
			active: !currentActive,
		});
	}

	async function handleCreateFreeProduct() {
		const newFreeProduct = await createFreeProduct();
		history.push(`/freeProducts/${newFreeProduct._id}`);
	}

	if (freeProductsError || freeProductError) {
		return <Error error={freeProductsError || freeProductError} />;
	}

	if (freeProductsLoading) {
		return <Loading />;
	}

	function renderFreeProductsList() {
		if (!freeProducts.length) {
			return null;
		}

		return (
			<TableContainer component={Paper} className={classes.paper}>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell>Id</TableCell>
							<TableCell>Sticky Id</TableCell>
							<TableCell>Name</TableCell>
							<TableCell>Image</TableCell>
							<TableCell>Active</TableCell>
							<TableCell align='right'>Delete</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{freeProducts
							.sort((a, b) => a.order - b.order)
							.map((row) => (
								<TableRow key={row._id} hover>
									<TableCell component='th' scope='row'>
										<Link
											component='button'
											variant='body2'
											onClick={() => {
												history.push(`/freeProducts/${row._id}`);
											}}
										>
											{row._id}
										</Link>
									</TableCell>
									<TableCell>{row.stickyId}</TableCell>
									<TableCell>{row.name}</TableCell>
									<TableCell>
										<img src={row.imageSrc} alt='review' style={{ height: '38px', width: 'auto' }} />
									</TableCell>
									<TableCell>
										<Checkbox
											checked={row.active}
											color='primary'
											onClick={() => handleActivateFreeProduct(row._id, row.active)}
										/>
									</TableCell>
									<TableCell align='right'>
										<IconButton
											color='secondary'
											aria-label='Delete review'
											component='span'
											onClick={() => handleDeleteFreeProduct(row._id)}
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	}

	return (
		<>
			<Modal open={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
				<Box>
					<Typography variant='h6' gutterBottom>
						Are you sure you want to delete this free product?
					</Typography>
				</Box>
				<Box className={classes.modalButtons}>
					<Button
						variant='contained'
						color='primary'
						style={{ marginRight: '10px' }}
						onClick={async () => {
							setDeleteModalOpen(false);
						}}
					>
						No
					</Button>
					<Button
						variant='contained'
						color='secondary'
						onClick={async () => {
							await deleteFreeProduct(freeProductToDelete);
							setDeleteModalOpen(false);
						}}
					>
						Yes
					</Button>
				</Box>
			</Modal>
			<Container className={classes.container}>
				<Box className={classes.topButtons}>
					<Button
						variant='contained'
						color='primary'
						startIcon={<AddIcon />}
						onClick={() => handleCreateFreeProduct()}
					>
						Add
					</Button>
				</Box>
				{renderFreeProductsList()}
			</Container>
		</>
	);
}
