import axios from 'axios';

const url =
	process.env.NODE_ENV === 'development'
		? 'http://localhost:6005'
		: 'https://launch-ra-api-92gp7.ondigitalocean.app';

export const api = axios.create({
	baseURL: url,
});
