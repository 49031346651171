import ReactJson from 'react-json-view';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { TableBody } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
}));

export default function HistoryTable({ data, pagination, onPaginationChange }) {
	const classes = useStyles();

	function handleChangePage(event, newPage) {
		onPaginationChange({
			...pagination,
			page: newPage,
		});
	}

	function handleChangeRowsPerPage(event) {
		onPaginationChange({
			limit: event.target.value,
			page: 0,
		});
	}

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<TableContainer>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell align='left'>Time</TableCell>
								<TableCell align='left'>Request</TableCell>
								<TableCell align='left'>Response</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((row) => (
								<TableRow key={row._id}>
									<TableCell component='th' scope='row'>
										<Typography variant='caption' gutterBottom>
											{moment(row.createdAt).format('LLL')}
										</Typography>
									</TableCell>
									<TableCell>
										{
											<ReactJson
												src={row.request}
												collapsed={true}
												iconStyle='triangle'
												indentWidth={2}
												enableClipboard={true}
												displayObjectSize={false}
												displayDataTypes={false}
												style={{
													fontSize: '12px',
												}}
											/>
										}
									</TableCell>
									<TableCell>
										{
											<ReactJson
												src={row.response}
												collapsed={true}
												iconStyle='triangle'
												indentWidth={2}
												enableClipboard={true}
												displayObjectSize={false}
												displayDataTypes={false}
												style={{
													fontSize: '12px',
												}}
											/>
										}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					component='div'
					count={data.length}
					rowsPerPageOptions={[25, 50, 100]}
					rowsPerPage={pagination.limit}
					page={pagination.page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</div>
	);
}
