import React, { useContext, useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Context as OfferContext } from '../../context/OfferContext';
import { Context as PromoContext } from '../../context/PromoContext';

import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	cardsContainer: {
		display: 'flex',
		marginBottom: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
		overflowX: 'scroll',
		flexWrap: 'wrap',
	},
	title: {
		fontWeight: '500',
		marginBottom: theme.spacing(1),
	},
	productCard: {
		width: `calc(25% - ${theme.spacing(2)}px)`,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		margin: theme.spacing(1),
		padding: 0,
	},
	productCardPopular: {
		color: 'white',
		textAlign: 'center',
		backgroundColor: '#4050b5',
	},
	productCardContent: {
		flex: '1',
	},
	syncButton: {
		width: '100%',
		marginBottom: theme.spacing(3),
	},
	textField: {
		marginBottom: theme.spacing(2),
	},
}));

export default function ProductsList({ offer, onSyncClick, onMostPopularClick, isPromo = false }) {
	const classes = useStyles();

	const [productEdits, setProductEdits] = useState({});
	const { updateOffer } = useContext(OfferContext);
	const { updatePromo } = useContext(PromoContext);

	useEffect(() => {
		// Initialize or update the local edits state
		const edits = {};
		offer.productsData.forEach((product) => {
			edits[product.id] = {
				offerId: product.offerId || '',
				billingModelId: product.billingModelId || '',
			};
		});
		setProductEdits(edits);
	}, [offer.productsData]);

	function handleCheckboxClick(productId, isChecked) {
		if (isChecked) {
			onMostPopularClick([...offer.mostPopularProductIds, productId]);
		} else {
			onMostPopularClick(offer.mostPopularProductIds.filter((item) => item !== productId));
		}
	}

	function handleFieldChange(productId, field, value) {
		// Update local state for UI rendering
		setProductEdits({
			...productEdits,
			[productId]: {
				...productEdits[productId],
				[field]: value,
			},
		});
	}

	function handleFieldBlur(productId) {
		const currentDetails = offer.productDetails[productId] || {};
		const editDetails = productEdits[productId] || {};

		// Prepare the updated details by merging, but only for fields present and defined in editDetails
		const updatedDetails = { ...currentDetails };
		Object.keys(editDetails).forEach((key) => {
			if (editDetails[key] !== undefined && editDetails[key] !== '') {
				updatedDetails[key] = editDetails[key];
			}
		});

		// Prepare the updated productDetails for the entire offer
		const updatedProductDetails = {
			...offer.productDetails,
			[productId]: updatedDetails,
		};

		// Update the offer or promo with the new productDetails
		if (isPromo) {
			updatePromo(offer._id, { productDetails: updatedProductDetails });
		} else {
			updateOffer(offer._id, { productDetails: updatedProductDetails });
		}
	}

	return (
		<>
			<Box className={classes.cardsContainer}>
				{offer.productsData.map((item, index) => (
					<Card key={index} variant='outlined' className={classes.productCard}>
						<CardContent className={classes.productCardContent}>
							<Typography className={classes.title} variant='subtitle1' gutterBottom>
								Sticky PID - {item.id}
							</Typography>
							<Typography className={classes.textField} variant='body1' gutterBottom>
								{item.name}
							</Typography>
							<TextField
								fullWidth
								className={classes.textField}
								label='Sticky Offer ID'
								value={productEdits[item.id]?.offerId || offer.productDetails[item.id]?.offerId || ''}
								onChange={(e) => handleFieldChange(item.id, 'offerId', e.target.value)}
								onBlur={() => handleFieldBlur(item.id)}
							/>
							<TextField
								fullWidth
								className={classes.textField}
								label='Billing Model ID'
								value={
									productEdits[item.id]?.billingModelId || offer.productDetails[item.id]?.billingModelId || ''
								}
								onChange={(e) => handleFieldChange(item.id, 'billingModelId', e.target.value)}
								onBlur={() => handleFieldBlur(item.id)}
							/>

							<Typography style={{ fontWeight: 'bold' }} variant='caption'>
								Price - ${item.price}
							</Typography>
							<Typography variant='caption' style={{ display: 'block' }}>
								Shipping id: {item.shippingId} (${item.shippingPrice})
							</Typography>
							<Typography variant='caption' style={{ display: 'block' }}>
								MSRP - ${item.oldPrice}
							</Typography>
							{item.soldOut && (
								<Typography variant='caption' style={{ display: 'block' }}>
									<b style={{ color: 'red' }}>SOLD OUT</b>
								</Typography>
							)}
							<FormControlLabel
								control={
									<Checkbox
										checked={offer.mostPopularProductIds.includes(item.id)}
										onChange={(e) => handleCheckboxClick(item.id, e.target.checked)}
										name='popular'
										color='primary'
									/>
								}
								label='Most Popular'
							/>
						</CardContent>
					</Card>
				))}
			</Box>
			<Button variant='contained' color='primary' className={classes.syncButton} onClick={onSyncClick}>
				Sync products
			</Button>
		</>
	);
}
