import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Context as UserContext } from '../../context/UserContext';
import { Context as SpecialOfferContext } from '../../context/SpecialOfferContext';
import GalleryItem from '../../components/GalleryItem/GalleryItem';
import ChipsList from '../../components/ChipsList/ChipsList';
import Error from '../../components/Error/Error';
import { Paper } from '@material-ui/core';
import specialOfferTemplates from '../../utils/specialOfferTemplates';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(3),
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  products: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    '& $textField': {
      marginRight: theme.spacing(2),
      marginBottom: 0,
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
  },
  productRow: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  iconsGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  productsIcon: {
    alignSelf: 'center',
    marginLeft: theme.spacing(1),
  },
  pageActionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },
}));

export default function SpecialOfferPage({ onRender }) {
  // VARS
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();

  // STATE
  const [name, setName] = useState('');
  const [headline, setHeadline] = useState('');
  const [products, setProducts] = useState([]);
  const [gallery, setGallery] = useState([]);

  // CONTEXTS
  const {
    state: { user },
  } = useContext(UserContext);

  const {
    state: { specialOffer, loading: specialOfferLoading, error: specialOfferError },
    loadSpecialOffer,
    updateSpecialOffer,
    createSpecialOffer,
  } = useContext(SpecialOfferContext);

  // EFFECTS
  useEffect(() => {
    if (user) {
      loadSpecialOffer(id);
    }
    return () => {
      // Reset state when the component unmounts or when navigating to a new offer
      setName('');
      setHeadline('');
      setProducts([]);
      setGallery([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, id]);

  useEffect(() => {
    if (specialOffer) {
      onRender(`Special offer - ${specialOffer.name}`);
      // Set values after special offer is loaded
      setName(specialOffer.name);
      setHeadline(specialOffer.headline);
      setProducts(specialOffer.products);
      setGallery(specialOffer.gallery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialOffer]);

  if (specialOfferError) {
    return <Error error={specialOfferError} />;
  }

  if (!specialOffer) {
    return null;
  }

  function handleSpecialOfferUpdate(update) {
    updateSpecialOffer(id, update);
  }

  function renderProducts() {
    function handleUpdateProduct(e, index) {
      const newProducts = [...products];
      newProducts[index][e.target.name] = e.target.value;
      setProducts(newProducts);
    }

    function handleAddProduct() {
      handleSpecialOfferUpdate({
        products: [
          ...products,
          {
            id: '',
            qty: '',
            discount: '',
            name: '',
            price: '',
            msrp: '',
            color: '',
            colorImage: '',
            offerId: '',
            billingModelId: '',
          },
        ],
      });
    }

    function handleDeleteProduct(id) {
      handleSpecialOfferUpdate({
        products: products.filter((item) => item.id !== id),
      });
    }

    return (
      <>
        <Typography variant='subtitle2' gutterBottom>
          Products
        </Typography>
        {products.map((item, index) => (
          <Box className={classes.products} key={index}>
            <Box>
              <Box className={classes.productRow}>
                <TextField
                  name='id'
                  label='Sticky PID'
                  value={item.id}
                  InputLabelProps={{ shrink: true }}
                  style={{ flex: 1 }}
                  className={classes.textField}
                  onChange={(e) => handleUpdateProduct(e, index)}
                  onBlur={() =>
                    handleSpecialOfferUpdate({
                      products,
                    })
                  }
                />
                <TextField
                  name='offerId'
                  label='Sticky Offer Id'
                  value={item.offerId}
                  InputLabelProps={{ shrink: true }}
                  style={{ flex: 1 }}
                  className={classes.textField}
                  onChange={(e) => handleUpdateProduct(e, index)}
                  onBlur={() =>
                    handleSpecialOfferUpdate({
                      products,
                    })
                  }
                />
                <TextField
                  name='billingModelId'
                  label='Billing Model Id'
                  value={item.billingModelId}
                  InputLabelProps={{ shrink: true }}
                  style={{ flex: 1 }}
                  className={classes.textField}
                  onChange={(e) => handleUpdateProduct(e, index)}
                  onBlur={() =>
                    handleSpecialOfferUpdate({
                      products,
                    })
                  }
                />
                <TextField
                  name='name'
                  label='Name'
                  value={item.name}
                  InputLabelProps={{ shrink: true }}
                  className={classes.textField}
                  style={{ flex: 3 }}
                  onChange={(e) => handleUpdateProduct(e, index)}
                  onBlur={() =>
                    handleSpecialOfferUpdate({
                      products,
                    })
                  }
                />
                <TextField
                  name='price'
                  label='Price $'
                  helperText='Example: 29.99'
                  value={item.price}
                  InputLabelProps={{ shrink: true }}
                  style={{ flex: 1 }}
                  className={classes.textField}
                  onChange={(e) => handleUpdateProduct(e, index)}
                  onBlur={() =>
                    handleSpecialOfferUpdate({
                      products,
                    })
                  }
                />
                <TextField
                  name='msrp'
                  label='MSRP $'
                  helperText='Example: 49.99'
                  value={item.msrp}
                  InputLabelProps={{ shrink: true }}
                  style={{ flex: 1 }}
                  className={classes.textField}
                  onChange={(e) => handleUpdateProduct(e, index)}
                  onBlur={() =>
                    handleSpecialOfferUpdate({
                      products,
                    })
                  }
                />
              </Box>
              <Box className={classes.productRow}>
                <TextField
                  name='qty'
                  label='Quantity'
                  helperText='Note: How many items in the package'
                  value={item.qty}
                  InputLabelProps={{ shrink: true }}
                  style={{ flex: 1 }}
                  className={classes.textField}
                  onChange={(e) => handleUpdateProduct(e, index)}
                  onBlur={() =>
                    handleSpecialOfferUpdate({
                      products,
                    })
                  }
                />
                <TextField
                  name='discount'
                  label='Discount %'
                  helperText='Example: 50'
                  value={item.discount}
                  InputLabelProps={{ shrink: true }}
                  style={{ flex: 1 }}
                  className={classes.textField}
                  onChange={(e) => handleUpdateProduct(e, index)}
                  onBlur={() =>
                    handleSpecialOfferUpdate({
                      products,
                    })
                  }
                />
                <TextField
                  name='color'
                  label='Color'
                  helperText='Example: black'
                  value={item.color}
                  InputLabelProps={{ shrink: true }}
                  style={{ flex: 1 }}
                  className={classes.textField}
                  onChange={(e) => handleUpdateProduct(e, index)}
                  onBlur={() =>
                    handleSpecialOfferUpdate({
                      products,
                    })
                  }
                />
                <TextField
                  name='colorImage'
                  label='Color Image Source'
                  helperText='Example: https://...'
                  value={item.colorImage}
                  InputLabelProps={{ shrink: true }}
                  style={{ flex: 2 }}
                  className={classes.textField}
                  onChange={(e) => handleUpdateProduct(e, index)}
                  onBlur={() =>
                    handleSpecialOfferUpdate({
                      products,
                    })
                  }
                />
              </Box>
            </Box>
            <Box className={classes.iconsGroup}>
              <IconButton
                color='secondary'
                aria-label='Delete'
                component='span'
                size='small'
                onClick={() => handleDeleteProduct(item.id)}
                className={classes.productsIcon}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                color='primary'
                aria-label='Add'
                component='span'
                size='small'
                onClick={() => handleAddProduct()}
                className={classes.productsIcon}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
        {!products.length && (
          <Box className={classes.iconsGroup}>
            <IconButton
              color='primary'
              aria-label='Add'
              component='span'
              size='small'
              onClick={() => handleAddProduct()}
              className={classes.productsIcon}
            >
              <AddIcon />
            </IconButton>
          </Box>
        )}
      </>
    );
  }

  function renderGallery() {
    function handleAddGalleryItem() {
      handleSpecialOfferUpdate({
        gallery: [
          ...gallery,
          {
            source: '',
            thumbnail: '',
            type: 'image',
          },
        ],
      });
    }

    function handleUpdateGalleryItem(update, index) {
      const newGallery = [...gallery];
      const entries = Object.entries(update)[0];

      newGallery[index][entries[0]] = entries[1];
      setGallery(newGallery);
      handleSpecialOfferUpdate({ gallery: newGallery });
    }

    function handleDeleteGalleryItem(itemIndex) {
      handleSpecialOfferUpdate({
        gallery: gallery.filter((item, index) => index !== itemIndex),
      });
    }

    return (
      <>
        <Typography variant='subtitle2' gutterBottom>
          Gallery
        </Typography>
        {gallery.map((item, index) => (
          <Box className={classes.products} key={index}>
            <GalleryItem
              key={`${item.source}-${index}`} // Ensure this key is unique for each item
              index={index}
              source={item.source}
              type={item.type}
              thumbnail={item.thumbnail}
              onUpdate={handleUpdateGalleryItem}
            />
            <Box className={classes.iconsGroup}>
              <IconButton
                color='secondary'
                aria-label='Delete'
                component='span'
                size='small'
                onClick={() => handleDeleteGalleryItem(index)}
                className={classes.productsIcon}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                color='primary'
                aria-label='Add'
                component='span'
                size='small'
                onClick={() => handleAddGalleryItem()}
                className={classes.productsIcon}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
        {!gallery.length && (
          <Box className={classes.iconsGroup}>
            <IconButton
              color='primary'
              aria-label='Add'
              component='span'
              size='small'
              onClick={() => handleAddGalleryItem()}
              className={classes.productsIcon}
            >
              <AddIcon />
            </IconButton>
          </Box>
        )}
      </>
    );
  }

  function renderPageActionButtons() {
    async function onCloneClick() {
      const clone = specialOffer;
      delete clone.createdAt;
      delete clone._id;
      delete clone.__v;
      clone.name += ' clone';

      try {
        const created = await createSpecialOffer(clone);
        history.push({});
        history.push(`/specialOffers/${created._id}`);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error: ', error);
      }
    }

    return (
      <Box className={classes.pageActionButtons}>
        <Button
          variant='contained'
          color='primary'
          endIcon={<ContentCopyIcon />}
          onClick={() => onCloneClick()}
        >
          Clone
        </Button>
      </Box>
    );
  }

  const Delivered = () => {
    const [value, setValue] = useState('');
    useEffect(() => {
      setValue(specialOffer.delivered);
    }, []);

    return (
      <TextField
        label='Delivered'
        helperText='Ex: 18,500'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() =>
          handleSpecialOfferUpdate({
            delivered: value,
          })
        }
        fullWidth
        className={classes.textField}
      />
    );
  };

  const Disclaimer = () => {
    const [value, setValue] = useState('');
    useEffect(() => {
      setValue(specialOffer.disclaimer);
    }, []);

    return (
      <TextField
        label='Disclaimer'
        value={value}
        multiline
        minRows={3}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() =>
          handleSpecialOfferUpdate({
            disclaimer: value,
          })
        }
        fullWidth
        className={classes.textField}
      />
    );
  };

  return (
    <Container maxWidth='lg' className={classes.container}>
      {specialOfferLoading && <LinearProgress className={classes.progress} />}
      {renderPageActionButtons()}
      <Paper className={classes.paper}>
        <TextField
          label='Id'
          value={specialOffer._id}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          className={classes.textField}
        />
        <TextField
          label='Name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={() =>
            handleSpecialOfferUpdate({
              name,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <FormControl fullWidth className={classes.textField}>
          <InputLabel>Template</InputLabel>
          <Select
            value={specialOffer.template.id}
            label='Template'
            onChange={(e) => {
              handleSpecialOfferUpdate({
                template: {
                  id: e.target.value,
                  name: specialOfferTemplates[e.target.value],
                },
              });
            }}
          >
            {specialOfferTemplates.map((name, index) => (
              <MenuItem key={index} value={index}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label='Headline'
          value={headline}
          onChange={(e) => setHeadline(e.target.value)}
          onBlur={() =>
            handleSpecialOfferUpdate({
              headline,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <Delivered />
        <Disclaimer />
        <ChipsList
          items={specialOffer.benefits}
          onUpdate={(newItems) => handleSpecialOfferUpdate({ benefits: newItems })}
          title='Benefits'
        />
        {renderGallery()}
        {renderProducts()}
      </Paper>
    </Container>
  );
}

SpecialOfferPage.propTypes = {
  onRender: PropTypes.func,
};
